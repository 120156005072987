import React from 'react';
import Navigation from '../components/home/Navigation';
import AllProducts from '../components/home/AllProducts';
import Footer from '../components/home/Footer';

const AllProductsPage = () => {
  return (
    <>
     <Navigation/>
     <AllProducts/>
     <Footer/> 
    </>
  );
};

export default AllProductsPage;