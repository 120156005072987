import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArticleContext } from '../../contexts/ArticleContext';
import { IoAdd, IoRemove } from 'react-icons/io5';
import Card from './Card';
import { Pagination } from '@mui/material';

const AllProducts = () => {

  const { searchTerm } = useParams();
  const [results, setResults] = useState([]);
  const [isVisible, setIsVisible] = useState("")
  const { articles } = useContext(ArticleContext);
  const [subCategory, setSubCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 8;


  console.log(searchTerm)
  const handleSubCategoryChange = (newSubCategory) => {
    setSubCategory(newSubCategory);
  };

  // Fonction qui analyse caractère par caractère
  const searchArticles = useMemo(() => {
    if (!searchTerm || !articles) return [];

    const searchQuery = searchTerm?.toLowerCase();
    const subCategoryQuery = subCategory.toLowerCase();

    // Si une sous-catégorie est sélectionnée, filtrer uniquement par sous-catégorie
    if (subCategoryQuery) {
      return articles.filter((article) => {
        return article.subCategory?.toLowerCase() === subCategoryQuery;
      });
    }

    // Filtrer les articles en fonction de la correspondance caractère par caractère
    return articles.filter(article => {
      const nameMatch = article.name.toLowerCase().includes(searchQuery);
      const descMatch = article.description.toLowerCase().includes(searchQuery);
      const categoryMatch = article.category?.toLowerCase().includes(searchQuery);
      const subCategoryMatch = article.subCategory?.toLowerCase().includes(searchQuery);

      return nameMatch || descMatch || categoryMatch || subCategoryMatch;
    });
  }, [articles, searchTerm, subCategory]);

  useEffect(() => {
    setResults(searchArticles);
  }, [searchArticles]);

    // Gérer les articles affichés par page
    const paginatedArticles = useMemo(() => {
      const startIndex = (currentPage - 1) * articlesPerPage;
      const endIndex = startIndex + articlesPerPage;
      return searchArticles.slice(startIndex, endIndex);
    }, [searchArticles, currentPage]);
  
    useEffect(() => {
      setResults(paginatedArticles);
    }, [paginatedArticles]);

    // Gérer la pagination
    const handlePageChange = (event, value) => {
      setCurrentPage(value);
    };

  return (
    <>
      <div className='flex justify-between mx-6 my-3'>
        <div className="sidebar  has-scrollbar" data-mobile-menu="">
          <div className="sidebar-category">
            <div className="sidebar-top">
              <h2 className="sidebar-title">Catégories</h2>
            </div>
            <ul className="sidebar-menu-category-list">
              <li className="sidebar-menu-category">
                <button
                  className="sidebar-accordion-menu"
                  data-accordion-btn="" onClick={() => {
                    if (isVisible === 'Food') {
                      setIsVisible('')
                    } else (
                      setIsVisible('Food')
                    )
                  }}

                >
                  <div className="menu-title-flex">
                    <p className="menu-title">Food</p>
                  </div>
                  <div>
                    <IoAdd className={isVisible === 'Food' ? 'hidden' : 'block'} />
                    <IoRemove className={isVisible === 'Food' ? 'block' : 'hidden'} />
                  </div>
                </button>
                <ul className={isVisible === 'Food' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Fast Food')}>
                      <p className="product-name">Fast Food</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Fast Food').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Plats local')}>
                      <p className="product-name">Plats local</p>
                      <data
                        value={75}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Plats local').length}
                      </data>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="sidebar-menu-category">
                <button
                  className="sidebar-accordion-menu"
                  data-accordion-btn="" onClick={() => {
                    if (isVisible === 'Homme') {
                      setIsVisible('')
                    } else (
                      setIsVisible('Homme')
                    )
                  }}

                >
                  <div className="menu-title-flex">

                    <p className="menu-title">Homme</p>
                  </div>
                  <div>
                    <IoAdd className={isVisible === 'Homme' ? 'hidden' : 'block'} />
                    <IoRemove className={isVisible === 'Homme' ? 'block' : 'hidden'} />
                  </div>
                </button>
                <ul className={isVisible === 'Homme' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Tee-shirts')}>
                      <p className="product-name">Tee-shirts</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Tee-shirts').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Chemises')}>
                      <p className="product-name">Chemises</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Chemises').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Polos')}>
                      <p className="product-name">Polos</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Polos').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Shorts')}>
                      <p className="product-name">Shorts</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Shorts').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Sous-vêtements')}>
                      <p className="product-name">Sous-vêtements</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Sous-vêtements').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Pantalons')}>
                      <p className="product-name">Pantalons</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Pantalons').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Vestes et Manteaux')}>
                      <p className="product-name">Vestes et Manteaux</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Vestes et Manteaux').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Baskets')}>
                      <p className="product-name">Baskets</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Baskets').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Souliers')}>
                      <p className="product-name">Souliers</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Souliers').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Mocassins')}>
                      <p className="product-name">Mocassins</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Mocassins').length}
                      </data>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="sidebar-menu-category">
                <button
                  className="sidebar-accordion-menu"
                  data-accordion-btn="" onClick={() => {
                    if (isVisible === 'Femme') {
                      setIsVisible('')
                    } else (
                      setIsVisible('Femme')
                    )
                  }}

                >
                  <div className="menu-title-flex">

                    <p className="menu-title">Femme</p>
                  </div>
                  <div>
                    <IoAdd className={isVisible === 'Femme' ? 'hidden' : 'block'} />
                    <IoRemove className={isVisible === 'Femme' ? 'block' : 'hidden'} />
                  </div>
                </button>
                <ul className={isVisible === 'Femme' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Tee-shirts')}>
                      <p className="product-name">Tee-shirts</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Tee-shirts').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Robes')}>
                      <p className="product-name">Robes</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Robes').length}
                      </data>
                    </Link>
                  </li>

                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Jupes')}>
                      <p className="product-name">Jupes</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Jupes').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Lingeries')}>
                      <p className="product-name">Lingeries</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Lingeries').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Pantalons')}>
                      <p className="product-name">Pantalons</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Pantalons').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Sandales')}>
                      <p className="product-name">Sandales</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Sandales').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Baskets')}>
                      <p className="product-name">Baskets</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Baskets').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Talons')}>
                      <p className="product-name">Talons</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Talons').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Ballerines')}>
                      <p className="product-name">Ballerines</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Ballerines').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Escarpins')}>
                      <p className="product-name">Escarpins</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Escarpins').length}
                      </data>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="sidebar-menu-category">
                <button
                  className="sidebar-accordion-menu"
                  data-accordion-btn="" onClick={() => {
                    if (isVisible === 'Telephone et tablette') {
                      setIsVisible('')
                    } else (
                      setIsVisible('Telephone et tablette')
                    )
                  }}

                >
                  <div className="menu-title-flex">

                    <p className="menu-title">Telephone et tablette</p>
                  </div>
                  <div>
                    <IoAdd className={isVisible === 'Telephone et tablette' ? 'hidden' : 'block'} />
                    <IoRemove className={isVisible === 'Telephone et tablette' ? 'block' : 'hidden'} />
                  </div>
                </button>
                <ul className={isVisible === 'Telephone et tablette' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Téléphone portable')}>
                      <p className="product-name">Téléphone portable</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Téléphone portable').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Tablette')}>
                      <p className="product-name">Tablette</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Tablette').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Accessoires téléphonique')}>
                      <p className="product-name">Accessoires téléphonique</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Accessoires téléphonique').length}
                      </data>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="sidebar-menu-category">
                <button
                  className="sidebar-accordion-menu"
                  data-accordion-btn="" onClick={() => {
                    if (isVisible === 'TV et Electronics') {
                      setIsVisible('')
                    } else (
                      setIsVisible('TV et Electronics')
                    )
                  }}

                >
                  <div className="menu-title-flex">

                    <p className="menu-title">TV et Electronics</p>
                  </div>
                  <div>
                    <IoAdd className={isVisible === 'TV et Electronics' ? 'hidden' : 'block'} />
                    <IoRemove className={isVisible === 'TV et Electronics' ? 'block' : 'hidden'} />
                  </div>
                </button>
                <ul className={isVisible === 'TV et Electronics' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Télévisions')}>
                      <p className="product-name">Télévisions</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Télévisions').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Audio et Hifi')}>
                      <p className="product-name">Audio et Hifi</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Audio et Hifi').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Accessoires Tv')}>
                      <p className="product-name">Accessoires Tv</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Accessoires Tv').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Photos et caméras')}>
                      <p className="product-name">Photos et caméras</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Photos et caméras').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Jeux et consoles')}>
                      <p className="product-name">Jeux et consoles</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Jeux et consoles').length}
                      </data>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="sidebar-menu-category">
                <button
                  className="sidebar-accordion-menu"
                  data-accordion-btn="" onClick={() => {
                    if (isVisible === 'Ferme') {
                      setIsVisible('')
                    } else (
                      setIsVisible('Ferme')
                    )
                  }}

                >
                  <div className="menu-title-flex">

                    <p className="menu-title">Ferme</p>
                  </div>
                  <div>
                    <IoAdd className={isVisible === 'Ferme' ? 'hidden' : 'block'} />
                    <IoRemove className={isVisible === 'Ferme' ? 'block' : 'hidden'} />
                  </div>
                </button>
                <ul className={isVisible === 'Ferme' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Volailles')}>
                      <p className="product-name">Volailles</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Volailles').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Bœufs')}>
                      <p className="product-name">Bœufs</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Bœufs').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Moutons')}>
                      <p className="product-name">Moutons</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Moutons').length}
                      </data>
                    </Link>
                  </li>
                  <li className="sidebar-submenu-category">
                    <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Lapins')}>
                      <p className="product-name">Lapins</p>
                      <data
                        value={45}
                        className="stock"
                        title="Available Stock"
                      >
                        {articles.filter((item) => item.subCategory === 'Lapins').length}
                      </data>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

        </div>
        <div className='p-3 rounded-lg shadow-lg border w-[75%]'>
          <div className="search-results">
            <h2>Résultats de recherche pour : "{searchTerm}"</h2>

            {results.length > 0 ? (
              <div className="product-main">

                <div className="product-grid">
                  {results?.map(article => (
                    <Card articles={article} />
                  ))}
                </div>

              </div>
            ) : (
              <p>Aucun article ne correspond à votre recherche.</p>
            )}
            {/* Pagination */}
            <div className="flex justify-center mt-6">
              <Pagination
                count={Math.ceil(searchArticles.length / articlesPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default AllProducts;