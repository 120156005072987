import DetailsProducts from './layout/DetailsProducts';
import { CartProvider } from './contexts/CartContext';
import { ShopProvider } from './contexts/ShopContext';
import { UserProvider } from './contexts/UserContext';
import HomePage from './layout/HomePage';
import StorePage from './layout/ShopPage';
import './style/global.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import UserPage from './layout/UserPage';
import { ArticleProvider } from './contexts/ArticleContext';
import CartPage from './layout/CartPage';
import { UserContext } from './contexts/UserContext';
import { useContext } from 'react';
import AllProductsPage from './layout/AllProductsPage';


function App() {
  function ProtectedRoute({ children }) {
    const { user } = useContext(UserContext);

    if (!user) {
      // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
      return <Navigate to="/" replace />;
    }

    return children;
  }
  return (
    <>
     
        <CartProvider>
          <UserProvider>
            <ShopProvider>
              <ArticleProvider>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/userPage" element={<UserPage />} />

                  {/* <Route path="/userPage" element={
                  <ProtectedRoute>
                    <UserPage />
                  </ProtectedRoute>
                } /> */}

                  <Route path="/shop/:id" element={<StorePage />} />
                  <Route path="/details/:id" element={<DetailsProducts />} />
                  <Route path="/cart" element={<CartPage />} />
                  <Route path="/allProducts/:searchTerm" element={<AllProductsPage />} />
                </Routes>
              </ArticleProvider>
            </ShopProvider>
          </UserProvider>
        </CartProvider>
      

    </>
  );
}

export default App;
