import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaRegHeart, FaPhoneAlt, FaPhone } from "react-icons/fa";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { FaRegStar, FaStar, FaRegStarHalfStroke } from "react-icons/fa6";
import { IoBoatSharp, IoRocket, IoArrowUndo, IoBoat } from "react-icons/io5";
import {ArticleContext} from "../../contexts/ArticleContext"
import Card from './Card';

const Main = () => {
  const { articles, } = useContext(ArticleContext);
  const getRandomArticles = (articlesArray, count) => {
    const shuffled = [...articlesArray].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const filteredArticles = useMemo(() => {
    if (!articles || articles.length === 0) return [];

    let filtered = [];

    filtered = getRandomArticles(articles, 8);

    return filtered;
  }, [articles]);
  return (
    <>
      {/*google font link*/}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <div className="overlay" data-overlay="" />
      {/*NOTIFICATION TOAST*/}
      {/* <div className="notification-toast" data-toast="">
        <button className="toast-close-btn" data-toast-close="">
          <ion-icon name="close-outline" />
        </button>
        <div className="toast-banner">
          <img
            src="/images/shopping-cart.png"
            alt="shop-cart"
            width={80}
            height={70}
          />
        </div>
        <div className="toast-detail">
          <p className="toast-message ">12</p>
        </div>
      </div> */}
      {/* HEADER*/}

      {/*MAIN*/}
      <main>
        {/* BANNER*/}

         {/*<div className="banner">
          <div className="container">
            <div className="slider-container has-scrollbar">
              <div className="slider-item">
                <img
                  src="/images/home_banner.svg"
                  alt="women's latest fashion sale"
                  className="banner-img"
                />
                <div className="banner-content">
                  <p className="banner-subtitle">Trending item</p>
                  <h2 className="banner-title">Women's latest fashion sale</h2>
                  <p className="banner-text">
                    starting at $ <b>20</b>.00
                  </p>
                  <Link className="banner-btn">
                    Shop now
                  </Link>
                </div> 
              </div>
               <div className="slider-item">
                <img
                  src="/templates/banner-2.jpg"
                  alt="modern sunglasses"
                  className="banner-img"
                />
                <div className="banner-content">
                  <p className="banner-subtitle">Trending accessories</p>
                  <h2 className="banner-title">Modern sunglasses</h2>
                  <p className="banner-text">
                    starting at $ <b>15</b>.00
                  </p>
                  <Link className="banner-btn">
                    Shop now
                  </Link>
                </div>
              </div>
              <div className="slider-item">
                <img
                  src="/templates/banner-3.jpg"
                  alt="new fashion summer sale"
                  className="banner-img"
                />
                <div className="banner-content">
                  <p className="banner-subtitle">Sale Offer</p>
                  <h2 className="banner-title">New fashion summer sale</h2>
                  <p className="banner-text">
                    starting at $ <b>29</b>.99
                  </p>
                  <Link className="banner-btn">
                    Shop now
                  </Link>
                </div>
              </div> 
            </div>
          </div>
        </div>*/}
        {/*CATEGORY*/}
        {/* <div className="category">
          <div className="container">
            <div className="category-item-container has-scrollbar">
              <div className="category-item">
                <div className="category-img-box">
                  <img
                    src="/templates/icons/dress.svg"
                    alt="dress & frock"
                    width={30}
                  />
                </div>
                <div className="category-content-box">
                  <div className="category-content-flex">
                    <h3 className="category-item-title">Dress &amp; frock</h3>
                    <p className="category-item-amount">(53)</p>
                  </div>
                  <Link className="category-btn">
                    Show all
                  </Link>
                </div>
              </div>
              <div className="category-item">
                <div className="category-img-box">
                  <img
                    src="/templates/icons/coat.svg"
                    alt="winter wear"
                    width={30}
                  />
                </div>
                <div className="category-content-box">
                  <div className="category-content-flex">
                    <h3 className="category-item-title">Winter wear</h3>
                    <p className="category-item-amount">(58)</p>
                  </div>
                  <Link className="category-btn">
                    Show all
                  </Link>
                </div>
              </div>
              <div className="category-item">
                <div className="category-img-box">
                  <img
                    src="/templates/icons/glasses.svg"
                    alt="glasses & lens"
                    width={30}
                  />
                </div>
                <div className="category-content-box">
                  <div className="category-content-flex">
                    <h3 className="category-item-title">Glasses &amp; lens</h3>
                    <p className="category-item-amount">(68)</p>
                  </div>
                  <Link className="category-btn">
                    Show all
                  </Link>
                </div>
              </div>
              <div className="category-item">
                <div className="category-img-box">
                  <img
                    src="/templates/icons/shorts.svg"
                    alt="shorts & jeans"
                    width={30}
                  />
                </div>
                <div className="category-content-box">
                  <div className="category-content-flex">
                    <h3 className="category-item-title">Shorts &amp; jeans</h3>
                    <p className="category-item-amount">(84)</p>
                  </div>
                  <Link className="category-btn">
                    Show all
                  </Link>
                </div>
              </div>
              <div className="category-item">
                <div className="category-img-box">
                  <img
                    src="/templates/icons/tee.svg"
                    alt="t-shirts"
                    width={30}
                  />
                </div>
                <div className="category-content-box">
                  <div className="category-content-flex">
                    <h3 className="category-item-title">T-shirts</h3>
                    <p className="category-item-amount">(35)</p>
                  </div>
                  <Link className="category-btn">
                    Show all
                  </Link>
                </div>
              </div>
              <div className="category-item">
                <div className="category-img-box">
                  <img
                    src="/templates/icons/jacket.svg"
                    alt="jacket"
                    width={30}
                  />
                </div>
                <div className="category-content-box">
                  <div className="category-content-flex">
                    <h3 className="category-item-title">Jacket</h3>
                    <p className="category-item-amount">(16)</p>
                  </div>
                  <Link className="category-btn">
                    Show all
                  </Link>
                </div>
              </div>
              <div className="category-item">
                <div className="category-img-box">
                  <img
                    src="/templates/icons/watch.svg"
                    alt="watch"
                    width={30}
                  />
                </div>
                <div className="category-content-box">
                  <div className="category-content-flex">
                    <h3 className="category-item-title">Watch</h3>
                    <p className="category-item-amount">(27)</p>
                  </div>
                  <Link className="category-btn">
                    Show all
                  </Link>
                </div>
              </div>
              <div className="category-item">
                <div className="category-img-box">
                  <img
                    src="/templates/icons/hat.svg"
                    alt="hat & caps"
                    width={30}
                  />
                </div>
                <div className="category-content-box">
                  <div className="category-content-flex">
                    <h3 className="category-item-title">Hat &amp; caps</h3>
                    <p className="category-item-amount">(39)</p>
                  </div>
                  <Link className="category-btn">
                    Show all
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*PRODUCT*/}
        <div className="product-container">
          <div className="container">
            <div className="product-box">
              {/*AFFICHAGE DE PRODUITS MINIMALISTE PAR CATEGORIE*/}
              {/* <div className="product-minimal">
                <div className="product-showcase">
                  <h2 className="title">FOOD</h2>
                  <div className="showcase-wrapper has-scrollbar">
                    <div className="showcase-container">
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/clothes-1.jpg"
                            alt="relaxed short full sleeve t-shirt"
                            width={70}
                            className="showcase-img"
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Relaxed Short full Sleeve T-Shirt
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Clothes
                          </Link>
                          <div className="price-box">
                            <p className="price">$45.00</p>
                            <del>$12.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/clothes-2.jpg"
                            alt="girls pink embro design top"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Girls pnk Embro design Top
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Clothes
                          </Link>
                          <div className="price-box">
                            <p className="price">$61.00</p>
                            <del>$9.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/clothes-3.jpg"
                            alt="black floral wrap midi skirt"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Black Floral Wrap Midi Skirt
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Clothes
                          </Link>
                          <div className="price-box">
                            <p className="price">$76.00</p>
                            <del>$25.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/shirt-1.jpg"
                            alt="pure garment dyed cotton shirt"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Pure Garment Dyed Cotton Shirt
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Mens Fashion
                          </Link>
                          <div className="price-box">
                            <p className="price">$68.00</p>
                            <del>$31.00</del>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="showcase-container">
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/jacket-5.jpg"
                            alt="men yarn fleece full-zip jacket"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              MEN Yarn Fleece Full-Zip Jacket
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Winter wear
                          </Link>
                          <div className="price-box">
                            <p className="price">$61.00</p>
                            <del>$11.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/jacket-1.jpg"
                            alt="mens winter leathers jackets"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Mens Winter Leathers Jackets
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Winter wear
                          </Link>
                          <div className="price-box">
                            <p className="price">$32.00</p>
                            <del>$20.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/jacket-3.jpg"
                            alt="mens winter leathers jackets"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Mens Winter Leathers Jackets
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Jackets
                          </Link>
                          <div className="price-box">
                            <p className="price">$50.00</p>
                            <del>$25.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/shorts-1.jpg"
                            alt="better basics french terry sweatshorts"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Better Basics French Terry Sweatshorts
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Shorts
                          </Link>
                          <div className="price-box">
                            <p className="price">$20.00</p>
                            <del>$10.00</del>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-showcase">
                  <h2 className="title">VETEMENTS</h2>
                  <div className="showcase-wrapper  has-scrollbar">
                    <div className="showcase-container">
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/sports-1.jpg"
                            alt="running & trekking shoes - white"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Running &amp; Trekking Shoes - White
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Sports
                          </Link>
                          <div className="price-box">
                            <p className="price">$49.00</p>
                            <del>$15.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/sports-2.jpg"
                            alt="trekking & running shoes - black"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Trekking &amp; Running Shoes - black
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Sports
                          </Link>
                          <div className="price-box">
                            <p className="price">$78.00</p>
                            <del>$36.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/party-wear-1.jpg"
                            alt="womens party wear shoes"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Womens Party Wear Shoes
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Party wear
                          </Link>
                          <div className="price-box">
                            <p className="price">$94.00</p>
                            <del>$42.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/sports-3.jpg"
                            alt="sports claw women's shoes"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Sports Claw Women's Shoes
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Sports
                          </Link>
                          <div className="price-box">
                            <p className="price">$54.00</p>
                            <del>$65.00</del>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="showcase-container">
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/sports-6.jpg"
                            alt="air tekking shoes - white"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Air Trekking Shoes - white
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Sports
                          </Link>
                          <div className="price-box">
                            <p className="price">$52.00</p>
                            <del>$55.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/shoe-3.jpg"
                            alt="Boot With Suede Detail"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Boot With Suede Detail
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            boots
                          </Link>
                          <div className="price-box">
                            <p className="price">$20.00</p>
                            <del>$30.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/shoe-1.jpg"
                            alt="men's leather formal wear shoes"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Men's Leather Formal Wear shoes
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            formal
                          </Link>
                          <div className="price-box">
                            <p className="price">$56.00</p>
                            <del>$78.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/shoe-2.jpg"
                            alt="casual men's brown shoes"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Casual Men's Brown shoes
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Casual
                          </Link>
                          <div className="price-box">
                            <p className="price">$50.00</p>
                            <del>$55.00</del>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-showcase">
                  <h2 className="title">ELECTRONIQUE</h2>
                  <div className="showcase-wrapper  has-scrollbar">
                    <div className="showcase-container">
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/watch-3.jpg"
                            alt="pocket watch leather pouch"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Pocket Watch Leather Pouch
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Watches
                          </Link>
                          <div className="price-box">
                            <p className="price">$50.00</p>
                            <del>$34.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/jewellery-3.jpg"
                            alt="silver deer heart necklace"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Silver Deer Heart Necklace
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Jewellery
                          </Link>
                          <div className="price-box">
                            <p className="price">$84.00</p>
                            <del>$30.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/perfume.jpg"
                            alt="titan 100 ml womens perfume"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Titan 100 Ml Womens Perfume
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Perfume
                          </Link>
                          <div className="price-box">
                            <p className="price">$42.00</p>
                            <del>$10.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/belt.jpg"
                            alt="men's leather reversible belt"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Men's Leather Reversible Belt
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Belt
                          </Link>
                          <div className="price-box">
                            <p className="price">$24.00</p>
                            <del>$10.00</del>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="showcase-container">
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/jewellery-2.jpg"
                            alt="platinum zircon classic ring"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              platinum Zircon Classic Ring
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            jewellery
                          </Link>
                          <div className="price-box">
                            <p className="price">$62.00</p>
                            <del>$65.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/watch-1.jpg"
                            alt="smart watche vital plus"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Smart watche Vital Plus
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            Watches
                          </Link>
                          <div className="price-box">
                            <p className="price">$56.00</p>
                            <del>$78.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/shampoo.jpg"
                            alt="shampoo conditioner packs"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              shampoo conditioner packs
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            cosmetics
                          </Link>
                          <div className="price-box">
                            <p className="price">$20.00</p>
                            <del>$30.00</del>
                          </div>
                        </div>
                      </div>
                      <div className="showcase">
                        <Link className="showcase-img-box">
                          <img
                            src="/templates/products/jewellery-1.jpg"
                            alt="rose gold peacock earrings"
                            className="showcase-img"
                            width={70}
                          />
                        </Link>
                        <div className="showcase-content">
                          <Link>
                            <h4 className="showcase-title">
                              Rose Gold Peacock Earrings
                            </h4>
                          </Link>
                          <Link className="showcase-category">
                            jewellery
                          </Link>
                          <div className="price-box">
                            <p className="price">$20.00</p>
                            <del>$30.00</del>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/*GRANDE ANNONCE DE PRODUIT*/}
              {/* <div className="product-featured">
                <h2 className="title">Deal of the day</h2>
                <div className="showcase-wrapper has-scrollbar">
                  <div className="showcase-container">
                    <div className="showcase">
                      <div className="showcase-banner">
                        <img
                          src="/templates/products/shampoo.jpg"
                          alt="shampoo, conditioner & facewash packs"
                          className="showcase-img"
                        />
                      </div>
                      <div className="showcase-content">
                        <div className="showcase-rating">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaRegStar />
                          <FaRegStar />
                        </div>
                        <Link>
                          <h3 className="showcase-title">
                            shampoo, conditioner &amp; facewash packs
                          </h3>
                        </Link>
                        <p className="showcase-desc">
                          Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor
                          dolor sit amet consectetur Lorem ipsum dolor
                        </p>
                        <div className="price-box">
                          <p className="price">$150.00</p>
                          <del>$200.00</del>
                        </div>
                        <button className="add-cart-btn">add to cart</button>
                        <div className="showcase-status">
                          <div className="wrapper">
                            <p>
                              already sold: <b>20</b>
                            </p>
                            <p>
                              available: <b>40</b>
                            </p>
                          </div>
                          <div className="showcase-status-bar" />
                        </div>
                        <div className="countdown-box">
                          <p className="countdown-desc">Hurry Up! Offer ends in:</p>
                          <div className="countdown">
                            <div className="countdown-content">
                              <p className="display-number">360</p>
                              <p className="display-text">Days</p>
                            </div>
                            <div className="countdown-content">
                              <p className="display-number">24</p>
                              <p className="display-text">Hours</p>
                            </div>
                            <div className="countdown-content">
                              <p className="display-number">59</p>
                              <p className="display-text">Min</p>
                            </div>
                            <div className="countdown-content">
                              <p className="display-number">00</p>
                              <p className="display-text">Sec</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="showcase-container">
                    <div className="showcase">
                      <div className="showcase-banner">
                        <img
                          src="/templates/products/jewellery-1.jpg"
                          alt="Rose Gold diamonds Earring"
                          className="showcase-img"
                        />
                      </div>
                      <div className="showcase-content">
                        <div className="showcase-rating">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaRegStar />
                          <FaRegStar />
                        </div>
                        <h3 className="showcase-title">
                          <Link className="showcase-title">
                            Rose Gold diamonds Earring
                          </Link>
                        </h3>
                        <p className="showcase-desc">
                          Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor
                          dolor sit amet consectetur Lorem ipsum dolor
                        </p>
                        <div className="price-box">
                          <p className="price">$1990.00</p>
                          <del>$2000.00</del>
                        </div>
                        <button className="add-cart-btn">add to cart</button>
                        <div className="showcase-status">
                          <div className="wrapper">
                            <p>
                              {" "}
                              already sold: <b>15</b>{" "}
                            </p>
                            <p>
                              {" "}
                              available: <b>40</b>{" "}
                            </p>
                          </div>
                          <div className="showcase-status-bar" />
                        </div>
                        <div className="countdown-box">
                          <p className="countdown-desc">Hurry Up! Offer ends in:</p>
                          <div className="countdown">
                            <div className="countdown-content">
                              <p className="display-number">360</p>
                              <p className="display-text">Days</p>
                            </div>
                            <div className="countdown-content">
                              <p className="display-number">24</p>
                              <p className="display-text">Hours</p>
                            </div>
                            <div className="countdown-content">
                              <p className="display-number">59</p>
                              <p className="display-text">Min</p>
                            </div>
                            <div className="countdown-content">
                              <p className="display-number">00</p>
                              <p className="display-text">Sec</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/*PRODUCT GRID*/}
              <div className="product-main">
                <h2 className="title">Nos Produits</h2>
                <div className="product-grid">
                  {filteredArticles.map((article) => (
                    <Card articles={article}/>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*TESTIMONIALS, CTA & SERVICE*/}
        <div>
          <div className="container">
            <div className="testimonials-box">
              {/*TESTIMONIALS*/}
              <div className="testimonial">
                <h2 className="title">Nos partenaires</h2>
                <div className="testimonial-card">
                  <img
                    src="/images/person.png"
                    alt="alan doe"
                    className="testimonial-banner"
                    width={80}
                    height={80}
                  />
                  <p className="testimonial-name">Partenaire</p>
                  <p className="testimonial-title">CEO &amp; Founder Invision</p>
                  <img
                    src="/templates/icons/quotes.svg"
                    alt="quotation"
                    className="quotation-img"
                    width={26}
                  />
                  <p className="testimonial-desc">
                    Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor dolor
                    sit amet.
                  </p>
                </div>
              </div>
              {/*CTA*/}
              <div className="cta-container">
                <img
                  src="/templates/cta-banner.jpg"
                  alt="summer collection"
                  className="cta-banner"
                />
                <Link className="cta-content">
                  <p className="discount">25% Discount</p>
                  <h2 className="cta-title">Summer collection</h2>
                  <p className="cta-text">Starting @ $10</p>
                  <button className="cta-btn">Shop now</button>
                </Link>
              </div>
              {/*SERVICE*/}
              <div className="service">
                <h2 className="title">Nos services</h2>
                <div className="service-container">
                  <Link className="service-item">
                    <div className="service-icon">
                      <IoBoat />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title">Worldwide Delivery</h3>
                      <p className="service-desc">For Order Over $100</p>
                    </div>
                  </Link>
                  <Link className="service-item">
                    <div className="service-icon">
                      <IoRocket />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title">Next Day delivery</h3>
                      <p className="service-desc">UK Orders Only</p>
                    </div>
                  </Link>
                  <Link className="service-item">
                    <div className="service-icon">
                      <FaPhone />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title">Best Online Support</h3>
                      <p className="service-desc">Hours: 8AM - 11PM</p>
                    </div>
                  </Link>
                  <Link className="service-item">
                    <div className="service-icon">
                      <IoArrowUndo />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title">Return Policy</h3>
                      <p className="service-desc">Easy &amp; Free Return</p>
                    </div>
                  </Link>
                  {/* <Link className="service-item">
                <div className="service-icon">
                  <ion-icon name="ticket-outline" />
                </div>
                <div className="service-content">
                  <h3 className="service-title">30% money back</h3>
                  <p className="service-desc">For Order Over $100</p>
                </div>
              </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*BLOG*/}
        <div className="blog">
          <div className="container">
            <div className="blog-container has-scrollbar">
              <div className="blog-card">
                <Link>
                  <img
                    src="/templates/blog-1.jpg"
                    alt="Clothes Retail KPIs 2021 Guide for Clothes Executives"
                    width={300}
                    className="blog-banner"
                  />
                </Link>
                <div className="blog-content">
                  <Link className="blog-category">
                    Fashion
                  </Link>
                  <Link>
                    <h3 className="blog-title">
                      Clothes Retail KPIs 2021 Guide for Clothes Executives.
                    </h3>
                  </Link>
                  <p className="blog-meta">
                    By <cite>Mr Admin</cite> /{" "}
                    <time dateTime="2022-04-06">Apr 06, 2022</time>
                  </p>
                </div>
              </div>
              <div className="blog-card">
                <Link>
                  <img
                    src="/templates/blog-2.jpg"
                    alt="Curbside fashion Trends: How to Win the Pickup Battle."
                    className="blog-banner"
                    width={300}
                  />
                </Link>
                <div className="blog-content">
                  <Link className="blog-category">
                    Clothes
                  </Link>
                  <h3>
                    <Link className="blog-title">
                      Curbside fashion Trends: How to Win the Pickup Battle.
                    </Link>
                  </h3>
                  <p className="blog-meta">
                    By <cite>Mr Robin</cite> /{" "}
                    <time dateTime="2022-01-18">Jan 18, 2022</time>
                  </p>
                </div>
              </div>
              <div className="blog-card">
                <Link>
                  <img
                    src="/templates/blog-3.jpg"
                    alt="EBT vendors: Claim Your Share of SNAP Online Revenue."
                    className="blog-banner"
                    width={300}
                  />
                </Link>
                <div className="blog-content">
                  <Link className="blog-category">
                    Shoes
                  </Link>
                  <h3>
                    <Link className="blog-title">
                      EBT vendors: Claim Your Share of SNAP Online Revenue.
                    </Link>
                  </h3>
                  <p className="blog-meta">
                    By <cite>Mr Selsa</cite> /{" "}
                    <time dateTime="2022-02-10">Feb 10, 2022</time>
                  </p>
                </div>
              </div>
              <div className="blog-card">
                <Link>
                  <img
                    src="/templates/blog-4.jpg"
                    alt="Curbside fashion Trends: How to Win the Pickup Battle."
                    className="blog-banner"
                    width={300}
                  />
                </Link>
                <div className="blog-content">
                  <Link className="blog-category">
                    Electronics
                  </Link>
                  <h3>
                    <Link className="blog-title">
                      Curbside fashion Trends: How to Win the Pickup Battle.
                    </Link>
                  </h3>
                  <p className="blog-meta">
                    By <cite>Mr Pawar</cite> /{" "}
                    <time dateTime="2022-03-15">Mar 15, 2022</time>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*FOOTER*/}

    </>

  );
};

export default Main;