import React from 'react';
import Navigation from '../components/home/Navigation';
import Footer from '../components/home/Footer';
import Shop from '../components/home/Shop';

const StorePage = () => {
  return (
    <>
      <Navigation />
      <Shop />
      <Footer />
    </>
  );
};

export default StorePage;