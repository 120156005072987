import React from 'react';
import Navigation from '../components/home/Navigation';
import Cart from '../components/home/Cart';
import Footer from '../components/home/Footer';

const CartPage = () => {
  return (
    <div>
      <Navigation/>
      <Cart/>
      <Footer/>
    </div>
  );
};

export default CartPage;