import { useState, useContext, useEffect } from 'react';
import { Card } from 'flowbite-react';
import Pagination from '@mui/material/Pagination';
import { Avatar, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { FileUploader } from 'react-drag-drop-files';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { ArticleContext } from '../../contexts/ArticleContext';

const Article = ({ article = {}, onSuccess, shopId }) => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const { articles, fetchArticlesByShop, articlesByShop, updateArticle, deleteArticle } = useContext(ArticleContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(4);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (shopId) {
      fetchArticlesByShop(shopId);
    }
  }, [shopId, articles]);

  useEffect(() => {
    const uniqueCategories = [...new Set(articlesByShop.map(article => article.category))];
    setCategories(uniqueCategories);
  }, [articlesByShop]);

  const handleOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArticle(null);
  };

  const handleCategoryChange = (category) => {
    setCurrentPage(1);
    // You can add filtering logic here based on category
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articlesByShop.slice(indexOfFirstArticle, indexOfLastArticle);

  return (
    <>
      {/* Category Filter */}
      <div className="mb-4">
        <h2 className="text-lg font-semibold">Catégories</h2>
        <div className="flex gap-4">
          {categories.map(category => (
            <Button
              key={category}
              variant="outlined"
              onClick={() => handleCategoryChange(category)}
            >
              {category}
            </Button>
          ))}
        </div>
      </div>

      {/* Article Cards */}
      <div className="flex flex-wrap gap-4 justify-start">
        {currentArticles.map(article => (
          <Card
            key={article._id}
            className="w-[22%] p-4 shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300"
            imgAlt={article?.name}
            imgSrc={article?.imageUrls[0]}
          >
            <div className="flex flex-col justify-between h-full">
              <p className="text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                {article?.name}
              </p>
              <span className="text-lg font-bold text-gray-900 dark:text-white">
                {article.discount.applyDiscount ? article.discount.newPrice : article.price} FCFA
              </span>
              <div className="flex justify-between mt-4">
                <Avatar sx={{ width: '30px', height: '30px', cursor: 'pointer' }} onClick={() => handleOpen(article)}>
                  <HiOutlinePencilAlt />
                </Avatar>
                <Avatar sx={{ width: '30px', height: '30px', cursor: 'pointer' }} onClick={() => deleteArticle(article?._id)}>
                  <MdDelete />
                </Avatar>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-6">
        <Pagination
          className="flex flex-row space-x-2 items-center" // space-x-2 ajoute de l'espace entre les éléments
          count={Math.ceil(articlesByShop.length / articlesPerPage)}
          page={currentPage}
          onChange={(event, value) => paginate(value)}
          color="primary"
          size="large"
          shape="rounded"
        />
      </div>

      {/* Dialog for editing article */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Modifier l'article</DialogTitle>
        <DialogContent>
          <TextField fullWidth margin="normal" label="Nom" value={selectedArticle?.name || ''} onChange={(e) => setSelectedArticle({ ...selectedArticle, name: e.target.value })} />
          <TextField fullWidth margin="normal" label="Description" value={selectedArticle?.description || ''} onChange={(e) => setSelectedArticle({ ...selectedArticle, description: e.target.value })} />
          <TextField fullWidth margin="normal" label="Prix" value={selectedArticle?.price || ''} onChange={(e) => setSelectedArticle({ ...selectedArticle, price: e.target.value })} />
          <FormControlLabel label="Appliquer une réduction" control={<Checkbox checked={selectedArticle?.discount?.applyDiscount || false} onChange={(e) => setSelectedArticle({ ...selectedArticle, discount: { ...selectedArticle?.discount, applyDiscount: e.target.checked } })} />} />
          {selectedArticle?.discount?.applyDiscount && (
            <>
              <TextField fullWidth margin="normal" label="Pourcentage de réduction" value={selectedArticle?.discount?.percentDiscount} onChange={(e) => setSelectedArticle({ ...selectedArticle, discount: { ...selectedArticle?.discount, percentDiscount: e.target.value } })} />
              <TextField fullWidth margin="normal" label="Nouveau prix après réduction" value={selectedArticle?.discount?.newPrice || ''} aria-readonly />
            </>
          )}
          <TextField fullWidth margin="normal" label="Stock" value={selectedArticle?.stock || ''} onChange={(e) => setSelectedArticle({ ...selectedArticle, stock: e.target.value })} />
          <div className="mb-4">
            <label className="block text-primary-marineBlue font-semibold mb-2">Ajouter des images</label>
            <FileUploader handleChange={(fileList) => setSelectedArticle({ ...selectedArticle, imageUrls: fileList })} name="images" types={["JPG", "PNG", "GIF"]} multiple />
          </div>
          <Button variant="contained" color="primary" className="mt-4" onClick={() => updateArticle(selectedArticle?._id, selectedArticle)}>Mettre à jour</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Fermer</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ ...alert, open: false })}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Article;
