import React, { useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { CartContext } from '../../contexts/CartContext';  // Import du contexte
import { Link } from 'react-router-dom';
import { MdDeleteForever } from 'react-icons/md';

const Cart = () => {
  const {
    cart,
    canceledCart,
    customerInfo,
    setCustomerInfo,
    delivery,
    setDelivery,
    calculateTotal,
    updateCartItemQuantity,
    removeFromCart
  } = useContext(CartContext);

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
  const [error, setError] = useState(null);

  // Gestion du changement des informations du client
  const handleCustomerInfoChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleIncrease = (index) => {
    const currentItem = cart[index];
    updateCartItemQuantity(index, currentItem.quantity + 1); // Augmenter la quantité
  };

  const handleDecrease = (index) => {
    const currentItem = cart[index];
    if (currentItem.quantity > 1) {
      updateCartItemQuantity(index, currentItem.quantity - 1); // Diminuer la quantité
    }
  };

  const handleQuantityChange = (index, value) => {
    const quantity = parseInt(value, 10);
    if (!isNaN(quantity) && quantity >= 1) {
      updateCartItemQuantity(index, quantity); // Mettre à jour la quantité via le contexte
    }
  };

  return (
    <>
      <div className="bg-neutral-50 py-12  ">
        <div className="container mx-auto my-12">
          <div className="flex flex-col gap-6 md:flex-row">
            {cart.length === 0 ? (
              <p className='flex-1 shrink-0 rounded-sm border border-neutral-200 bg-white px-4 py-8 shadow-sm'>Votre panier est vide</p>
            ) : (
              <div className="flex-1 shrink-0 rounded-lg shadow-lg border-neutral-200 bg-white px-4 py-8 ">
                <div className="mb-8">
                  <h3 className="text-2xl font-bold">Vous avez {cart?.length} articles dans votre panier</h3>
                  <p>Bienvenue sur la page récapitulative de votre panier</p>
                </div>
                <ul role="list" className="-my-6 divide-y divide-neutral-200">
                  {cart.map((item, index) => (
                    <li key={index} className="flex py-6">
                      <div className="h-32 w-24 flex-shrink-0 overflow-hidden rounded-sm border bg-neutral-50">
                        <img
                          src={item?.imageUrls[0]}
                          alt="product"
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div className="ml-4 flex flex-1 flex-col">
                        <div>
                          <div className="flex justify-between text-sm text-gray-900">
                            <h3 className="text-base font-bold">
                              <div href="#"> {item?.name} </div>
                            </h3>
                            <button
                              type="button"
                              onClick={() => removeFromCart(index)}
                              className="flex gap-2 font-medium text-neutral-400 hover:text-neutral-900"
                            >
                              <p className="text-xs font-normal">supprimer</p>
                              <MdDeleteForever className="h-4 w-4" />
                            </button>
                          </div>
                          <div className="mt-2">
                            <p className="text-xs text-neutral-400">
                              {item?.description}
                            </p>
                          </div>
                        </div>
                        <div className="mt-auto flex flex-1 items-end justify-between text-sm">
                          <div className="flex items-center space-x-2">
                            <button
                              type="button"
                              onClick={() => handleDecrease(index)}
                              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                            >
                              -
                            </button>
                            <input
                              type="number"
                              id="quantity"
                              name="quantity"
                              value={item?.quantity}
                              onChange={(e) => handleQuantityChange(index, e.target.value)}
                              className="py-2 pl-3 text-center w-16 text-base focus:border-neutral-500 focus:outline-none focus:ring-neutral-500"
                              min="1"
                            />
                            <button
                              type="button"
                              onClick={() => handleIncrease(index)}
                              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                            >
                              +
                            </button>
                          </div>
                          <div className="flex">
                            <div className="text-right">
                              {item?.discount?.applyDiscount ? (
                                <>
                                  <p className="text-sm font-bold text-orange-600">{item?.discount?.newPrice} FCFA</p>
                                  <p className="text-xs text-gray-500">
                                    <span className="text-sm font-bold line-through">{item?.price} FCFA</span>
                                  </p>
                                  <p className="text-sm font-bold text-emerald-600">
                                    TOTAL : {item?.discount?.newPrice * item?.quantity} FCFA
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="text-sm font-bold">{item?.price} FCFA</p>
                                  <p className="text-sm font-bold text-emerald-600">
                                    TOTAL : {item?.price * item?.quantity} FCFA
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="sticky space-y-4 rounded-lg shadow-lg  border-neutral-200 bg-white py-6 px-4 sm:px-6 md:w-1/3">
              <h4 className="text-2xl font-bold">Résumé</h4>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between text-base text-gray-900">
                  <p>Total articles</p>
                  <p>{calculateTotal()} FCFA</p>
                </div>
                <div className="flex justify-between text-base text-gray-900">
                  <p>Frais de livraison</p>
                  <p>{delivery ? delivery.cost : 'Calculé à la livraison'} FCFA</p>
                </div>
                <div>
                  <div className="my-2 w-full border-t border-gray-300" />
                  <div className="flex justify-between text-base font-bold text-gray-900">
                    <p>Total</p>
                    <p>{calculateTotal()} FCFA</p>
                  </div>
                  <p className="mt-0.5 text-sm text-gray-400">Inclus TVA</p>
                </div>
                <div className="mt-auto flex flex-col gap-2 pt-4">
                  <a
                    href="#"
                    className="flex items-center justify-center rounded-md border border-transparent bg-neutral-800 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-neutral-900"
                  >
                    Valider la commande
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ ...alert, open: false })} >
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Cart;
