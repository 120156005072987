import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar, Alert, CircularProgress, Pagination } from '@mui/material';
import { FaFacebookF, FaHeart, FaHome, FaInstagram, FaLinkedin, FaSearch, FaShoppingCart, FaStar, FaStarHalf, FaTwitter } from "react-icons/fa";
import { IoAdd, IoClose, IoGrid, IoMenu, IoPerson, IoRemove } from "react-icons/io5";
import 'react-phone-number-input/style.css'
import { UserContext } from '../../contexts/UserContext';
import { CartContext } from '../../contexts/CartContext';
import { ArticleContext } from '../../contexts/ArticleContext';
import CardMinimalist from './CardMinimalist';


const Navigation = () => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState("")
  const [isCategoryVisible, setIsCategoryVisible] = useState(false)
  const { user, loginUser, registerUser, loading } = useContext(UserContext);
  const { cart } = useContext(CartContext);
  const { articles } = useContext(ArticleContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [subCategory, setSubCategory] = useState("Fast Food");
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 8;

  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [method, setMethod] = useState('login');
  const [password, setPassword] = useState('');
  // const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState();
  const [isSideBarOpen, setIsSideBarOpen] = useState();
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });

  const handleSubCategoryChange = (newSubCategory) => {
    setSubCategory(newSubCategory);
    setCurrentPage(1); // Reset to first page when category changes
  };

  const filteredArticles = useMemo(() => {
    if (!articles || articles.length === 0) return [];

    // Filtrer par sous-catégorie
    const filtered = articles.filter(article => article.subCategory === subCategory);

    return filtered;
  }, [articles, subCategory]);

  const paginatedArticles = useMemo(() => {
    const startIndex = (currentPage - 1) * articlesPerPage;
    const endIndex = startIndex + articlesPerPage;
    return filteredArticles.slice(startIndex, endIndex);
  }, [filteredArticles, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (user) {
      setOpen(false);
    }
  }, [user]);


  console.log(user)
  const handleOpenAccount = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMethodChange = useCallback(
    (event, value) => {
      setMethod(value);
    },
    []
  );

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const handleSideBar = () => {
    setIsSideBarOpen(!isSideBarOpen)
  }

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim() !== "") {
      navigate(`/AllProducts/${searchTerm}`);
    }
  };
  return (
    <>
      <header>
        <div className="header-top">
          <div className="container">
            <ul className="header-social-container">
              <li>
                <Link to={"/"} className="social-link">
                  <FaFacebookF />
                </Link>
              </li>
              <li>
                <Link to={"/"} className="social-link">
                  <FaTwitter />
                </Link>
              </li>
              <li>
                <Link to={"/"} className="social-link">
                  <FaInstagram />
                </Link>
              </li>
              <li>
                <Link to={"/"} className="social-link">
                  <FaLinkedin />
                </Link>
              </li>
            </ul>
            <div className="header-alert-news">
              <p>
                <b>Livraison gratuite</b>
                Cette semaine pour les commandes de plus de 15000 - FCFA
              </p>
            </div>
            {/* <div className="header-top-actions">
              <select name="currency">
                <option value="usd">USD $</option>
                <option value="eur">EUR €</option>
              </select>
              <select name="language">
                <option value="en-US">English</option>
                <option value="es-ES">Español</option>
                <option value="fr">Français</option>
              </select>
            </div> */}
          </div>
        </div>
        <div className="header-main">
          <div className="container">
            <Link to={"/"} className="header-logo">
              <img
                src="/images/logo_text_noir.png"
                alt="back market's logo"
                width={120}
                height={36}
              />
            </Link>
            <div className="header-search-container">
              <form onSubmit={handleSearch}>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  name="search"
                  className="search-field"
                  placeholder="Recherchez des articles..."
                />
                <button type="submit" className="search-btn">
                  <FaSearch />
                </button>
              </form>

            </div>
            <div className="header-user-actions">
              <button className="action-btn" onClick={user ? (() => navigate("/userPage")) : (handleOpenAccount)}>
                <IoPerson />
                {user? (<span className="connect">{" "}</span>):('')}
              </button>
              {location.pathname !== '/' ? (
                <button className="action-btn" onClick={() => setIsCategoryVisible(!isCategoryVisible)}>
                  <IoGrid />
                </button>
              ) : ('')}
              <Link to={"/cart"} className="action-btn">
                <FaShoppingCart />
                <span className="count">{cart.length}</span>
              </Link>
            </div>
          </div>
        </div>
        {/* <nav className="desktop-navigation-menu">
          <div className="container">
            <ul className="desktop-menu-category-list">
              <li className="menu-category">
                <Link to={"/"} className="menu-title">
                  Accueil
                </Link>
              </li>
              <li className="menu-category">
                <Link to={"/"} className="menu-title">
                  Catégories
                </Link>
                <div className="dropdown-panel">
                  <ul className="dropdown-panel-list">
                    <li className="menu-title">
                      <Link to={"/"}>TV et électronique</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Télévision</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Audio et wifi</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Accessoires TV</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Photo et caméra</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Jeux et consoles</Link>
                    </li>
                     <li className="panel-list-item">
                      <Link to={"/"}>
                        <img
                          src="/images/tv.png"
                          alt="headphone collection"
                          style={{width:'250px',height:'119px'}}
                          width={250}
                          height={119}
                        />
                      </Link>
                    </li> 
                  </ul>
                  <ul className="dropdown-panel-list">
                    <li className="menu-title">
                      <Link to={"/"}>Homme</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Montre</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>T-shirt</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Veste</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Basket</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Accessoires</Link>
                    </li>
                     <li className="panel-list-item">
                      <Link to={"/"}>
                        <img
                          src="/images/mens.png"
                          alt="men's fashion"
                          style={{width:'250px',height:'119px'}}
                          width={250}
                          height={119}
                        />
                      </Link>
                    </li> 
                  </ul>
                  <ul className="dropdown-panel-list">
                    <li className="menu-title">
                      <Link to={"/"}>Femme</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Robe</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Chaussures</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Parfum</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Cosmetique</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Accessoires</Link>
                    </li>
                     <li className="panel-list-item">
                      <Link to={"/"}>
                        <img
                          src="/images/womens.png"
                          alt="women's fashion"
                          width={250}
                          style={{width:'250px',height:'119px'}}
                          height={119}
                        />
                      </Link>
                    </li> 
                  </ul>
                  <ul className="dropdown-panel-list">
                    <li className="menu-title">
                      <Link to={"/"}>Repas</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Supermarché et boutique</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Restaurant africain</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Restaurant de variété</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Livraison rapide</Link>
                    </li>
                    <li className="panel-list-item">
                      <Link to={"/"}>Fast Food</Link>
                    </li>
                     <li className="panel-list-item">
                      <Link to={"/"}>
                        <img
                          src="/images/waitress.png"
                          alt="mouse collection"
                          width={250}
                          style={{height:'119px',objectFit:'cover'}}
                          height={119}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="menu-category">
                <Link to={"/"} className="menu-title">
                  HOMMES
                </Link>

              </li>
              <li className="menu-category">
                <Link to={"/"} className="menu-title">
                  FEMMES
                </Link>

              </li>
              <li className="menu-category">
                <Link to={"/"} className="menu-title">
                  Supermarché
                </Link>
              </li>
              <li className="menu-category">
                <Link to={"/"} className="menu-title">
                  INFORMATIQUE
                </Link>
              </li>
              <li className="menu-category">
                <Link to={"/"} className="menu-title">
                  TV ET ELECTRONIQUES
                </Link>
              </li>
              <li className="menu-category">
                <Link to={"/"} className="menu-title">
                  REPAS
                </Link>
              </li>
            </ul>
          </div>
        </nav> */}
        <div className="mobile-bottom-navigation">
          <button className="action-btn" data-mobile-menu-open-btn="" onClick={handleMenu}>
            <IoMenu />
          </button>
          <Link to={"/cart"} className="action-btn">
            <FaShoppingCart />
            <span className="count">{cart.length}</span>
          </Link>
          <Link to={"/"} className="action-btn">
            <FaHome />
          </Link>
          {/* <button className="action-btn">
            <FaHeart />
            <span className="count">0</span>
          </button> */}
          <button className="action-btn" data-mobile-menu-open-btn="" onClick={handleSideBar}>
            <IoGrid />
          </button>
        </div>
        <nav className={isMenuOpen ? "mobile-navigation-menu  has-scrollbar active" : "mobile-navigation-menu  has-scrollbar"} data-mobile-menu="">
          <div className="menu-top">
            <h2 className="menu-title">Menu</h2>
            <button className="menu-close-btn" data-mobile-menu-close-btn="" onClick={handleMenu}>
              <IoClose />
            </button>
          </div>
          <ul className="mobile-menu-category-list">
            <li className="menu-category">
              <Link to={"/"} className="menu-title">
                Home
              </Link>
            </li>
            <li className="menu-category">
              <button
                className="accordion-menu"
                data-accordion-btn=""
                onClick={() => {
                  if (isVisible === 'Homme') {
                    setIsVisible('')
                  } else (
                    setIsVisible('Homme')
                  )
                }}>
                <p className="menu-title">Men's</p>
                <div>
                  <IoAdd />
                  <IoRemove />
                </div>
              </button>
              <ul className="submenu-category-list" data-accordion="">
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Shirt
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Shorts &amp; Jeans
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Safety Shoes
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Wallet
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menu-category">
              <button
                className="accordion-menu"
                data-accordion-btn=""
                onClick={() => {
                  if (isVisible === 'Homme') {
                    setIsVisible('')
                  } else (
                    setIsVisible('Homme')
                  )
                }}>
                <p className="menu-title">Women's</p>
                <div>
                  <IoAdd />
                  <IoRemove />
                </div>
              </button>
              <ul className="submenu-category-list" data-accordion="">
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Dress &amp; Frock
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Earrings
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Necklace
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Makeup Kit
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menu-category">
              <button
                className="accordion-menu"
                data-accordion-btn=""
                onClick={() => {
                  if (isVisible === 'Homme') {
                    setIsVisible('')
                  } else (
                    setIsVisible('Homme')
                  )
                }}>
                <p className="menu-title">Jewelry</p>
                <div>
                  <IoAdd />
                  <IoRemove />
                </div>
              </button>
              <ul className="submenu-category-list" data-accordion="">
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Earrings
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Couple Rings
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Necklace
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Bracelets
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menu-category">
              <button
                className="accordion-menu"
                data-accordion-btn=""
                onClick={() => {
                  if (isVisible === 'Homme') {
                    setIsVisible('')
                  } else (
                    setIsVisible('Homme')
                  )
                }}>
                <p className="menu-title">Perfume</p>
                <div>
                  <IoAdd />
                  <IoRemove />
                </div>
              </button>
              <ul className="submenu-category-list" data-accordion="">
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Clothes Perfume
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Deodorant
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Flower Fragrance
                  </Link>
                </li>
                <li className="submenu-category">
                  <Link to={"/"} className="submenu-title">
                    Air Freshener
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menu-category">
              <Link to={"/"} className="menu-title">
                Blog
              </Link>
            </li>
            <li className="menu-category">
              <Link to={"/"} className="menu-title">
                Hot Offers
              </Link>
            </li>
          </ul>
          <div className="menu-bottom">
            <ul className="menu-social-container">
              <li>
                <Link to={"/"} className="social-link">
                  <FaFacebookF />
                </Link>
              </li>
              <li>
                <Link to={"/"} className="social-link">
                  <FaTwitter />
                </Link>
              </li>
              <li>
                <Link to={"/"} className="social-link">
                  <FaInstagram />
                </Link>
              </li>
              <li>
                <Link to={"/"} className="social-link">
                  <FaLinkedin />
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <div className={(isCategoryVisible || (location.pathname === '/')) ? 'block mt-10' : 'hidden'}>
          {/*- PRODUCT*/}
          <div className="product-container">
            <div className="container w-auto">


              {/*- SIDEBAR*/}
              <div className={isSideBarOpen ? "sidebar  has-scrollbar active" : "sidebar  has-scrollbar"} data-mobile-menu="">
                <div className="sidebar-category">
                  <div className="sidebar-top">
                    <h2 className="sidebar-title">Catégories</h2>
                    <button
                      className="sidebar-close-btn"
                      data-mobile-menu-close-btn=""
                      onClick={handleSideBar}
                    >
                      <IoClose />
                    </button>
                  </div>
                  <ul className="sidebar-menu-category-list">
                    <li className="sidebar-menu-category">
                      <button
                        className="sidebar-accordion-menu"
                        data-accordion-btn="" onClick={() => {
                          if (isVisible === 'Food') {
                            setIsVisible('')
                          } else (
                            setIsVisible('Food')
                          )
                        }}

                      >
                        <div className="menu-title-flex">
                          <p className="menu-title">Food</p>
                        </div>
                        <div>
                          <IoAdd className={isVisible === 'Food' ? 'hidden' : 'block'} />
                          <IoRemove className={isVisible === 'Food' ? 'block' : 'hidden'} />
                        </div>
                      </button>
                      <ul className={isVisible === 'Food' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Fast Food')}>
                            <p className="product-name">Fast Food</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Fast Food').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Plats local')}>
                            <p className="product-name">Plats local</p>
                            <data
                              value={75}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Plats local').length}
                            </data>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="sidebar-menu-category">
                      <button
                        className="sidebar-accordion-menu"
                        data-accordion-btn="" onClick={() => {
                          if (isVisible === 'Homme') {
                            setIsVisible('')
                          } else (
                            setIsVisible('Homme')
                          )
                        }}

                      >
                        <div className="menu-title-flex">

                          <p className="menu-title">Homme</p>
                        </div>
                        <div>
                          <IoAdd className={isVisible === 'Homme' ? 'hidden' : 'block'} />
                          <IoRemove className={isVisible === 'Homme' ? 'block' : 'hidden'} />
                        </div>
                      </button>
                      <ul className={isVisible === 'Homme' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Tee-shirts')}>
                            <p className="product-name">Tee-shirts</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Tee-shirts').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Chemises')}>
                            <p className="product-name">Chemises</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Chemises').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Polos')}>
                            <p className="product-name">Polos</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Polos').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Shorts')}>
                            <p className="product-name">Shorts</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Shorts').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Sous-vêtements')}>
                            <p className="product-name">Sous-vêtements</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Sous-vêtements').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Pantalons')}>
                            <p className="product-name">Pantalons</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Pantalons').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Vestes et Manteaux')}>
                            <p className="product-name">Vestes et Manteaux</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Vestes et Manteaux').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Baskets')}>
                            <p className="product-name">Baskets</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Baskets').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Souliers')}>
                            <p className="product-name">Souliers</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Souliers').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Mocassins')}>
                            <p className="product-name">Mocassins</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Mocassins').length}
                            </data>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="sidebar-menu-category">
                      <button
                        className="sidebar-accordion-menu"
                        data-accordion-btn="" onClick={() => {
                          if (isVisible === 'Femme') {
                            setIsVisible('')
                          } else (
                            setIsVisible('Femme')
                          )
                        }}

                      >
                        <div className="menu-title-flex">

                          <p className="menu-title">Femme</p>
                        </div>
                        <div>
                          <IoAdd className={isVisible === 'Femme' ? 'hidden' : 'block'} />
                          <IoRemove className={isVisible === 'Femme' ? 'block' : 'hidden'} />
                        </div>
                      </button>
                      <ul className={isVisible === 'Femme' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Tee-shirts')}>
                            <p className="product-name">Tee-shirts</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Tee-shirts').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Robes')}>
                            <p className="product-name">Robes</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Robes').length}
                            </data>
                          </Link>
                        </li>

                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Jupes')}>
                            <p className="product-name">Jupes</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Jupes').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Lingeries')}>
                            <p className="product-name">Lingeries</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Lingeries').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Pantalons')}>
                            <p className="product-name">Pantalons</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Pantalons').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Sandales')}>
                            <p className="product-name">Sandales</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Sandales').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Baskets')}>
                            <p className="product-name">Baskets</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Baskets').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Talons')}>
                            <p className="product-name">Talons</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Talons').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Ballerines')}>
                            <p className="product-name">Ballerines</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Ballerines').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Escarpins')}>
                            <p className="product-name">Escarpins</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Escarpins').length}
                            </data>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="sidebar-menu-category">
                      <button
                        className="sidebar-accordion-menu"
                        data-accordion-btn="" onClick={() => {
                          if (isVisible === 'Telephone et tablette') {
                            setIsVisible('')
                          } else (
                            setIsVisible('Telephone et tablette')
                          )
                        }}

                      >
                        <div className="menu-title-flex">

                          <p className="menu-title">Telephone et tablette</p>
                        </div>
                        <div>
                          <IoAdd className={isVisible === 'Telephone et tablette' ? 'hidden' : 'block'} />
                          <IoRemove className={isVisible === 'Telephone et tablette' ? 'block' : 'hidden'} />
                        </div>
                      </button>
                      <ul className={isVisible === 'Telephone et tablette' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Téléphone portable')}>
                            <p className="product-name">Téléphone portable</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Téléphone portable').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Tablette')}>
                            <p className="product-name">Tablette</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Tablette').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Accessoires téléphonique')}>
                            <p className="product-name">Accessoires téléphonique</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Accessoires téléphonique').length}
                            </data>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="sidebar-menu-category">
                      <button
                        className="sidebar-accordion-menu"
                        data-accordion-btn="" onClick={() => {
                          if (isVisible === 'TV et Electronics') {
                            setIsVisible('')
                          } else (
                            setIsVisible('TV et Electronics')
                          )
                        }}

                      >
                        <div className="menu-title-flex">

                          <p className="menu-title">TV et Electronics</p>
                        </div>
                        <div>
                          <IoAdd className={isVisible === 'TV et Electronics' ? 'hidden' : 'block'} />
                          <IoRemove className={isVisible === 'TV et Electronics' ? 'block' : 'hidden'} />
                        </div>
                      </button>
                      <ul className={isVisible === 'TV et Electronics' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Télévisions')}>
                            <p className="product-name">Télévisions</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Télévisions').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Audio et Hifi')}>
                            <p className="product-name">Audio et Hifi</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Audio et Hifi').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Accessoires Tv')}>
                            <p className="product-name">Accessoires Tv</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Accessoires Tv').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Photos et caméras')}>
                            <p className="product-name">Photos et caméras</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Photos et caméras').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Jeux et consoles')}>
                            <p className="product-name">Jeux et consoles</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Jeux et consoles').length}
                            </data>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="sidebar-menu-category">
                      <button
                        className="sidebar-accordion-menu"
                        data-accordion-btn="" onClick={() => {
                          if (isVisible === 'Ferme') {
                            setIsVisible('')
                          } else (
                            setIsVisible('Ferme')
                          )
                        }}

                      >
                        <div className="menu-title-flex">

                          <p className="menu-title">Ferme</p>
                        </div>
                        <div>
                          <IoAdd className={isVisible === 'Ferme' ? 'hidden' : 'block'} />
                          <IoRemove className={isVisible === 'Ferme' ? 'block' : 'hidden'} />
                        </div>
                      </button>
                      <ul className={isVisible === 'Ferme' ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Volailles')}>
                            <p className="product-name">Volailles</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Volailles').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Bœufs')}>
                            <p className="product-name">Bœufs</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Bœufs').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Moutons')}>
                            <p className="product-name">Moutons</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Moutons').length}
                            </data>
                          </Link>
                        </li>
                        <li className="sidebar-submenu-category">
                          <Link className="sidebar-submenu-title" onClick={() => handleSubCategoryChange('Lapins')}>
                            <p className="product-name">Lapins</p>
                            <data
                              value={45}
                              className="stock"
                              title="Available Stock"
                            >
                              {articles.filter((item) => item.subCategory === 'Lapins').length}
                            </data>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                {/* <div className="product-showcase">
                <h3 className="showcase-heading">best sellers</h3>
                <div className="showcase-wrapper">
                  <div className="showcase-container">
                    <div className="showcase">
                      <Link className="showcase-img-box">
                        <img
                          src="/templates/products/1.jpg"
                          alt="baby fabric shoes"
                          width={75}
                          height={75}
                          className="showcase-img"
                        />
                      </Link>
                      <div className="showcase-content">
                        <Link>
                          <h4 className="showcase-title">baby fabric shoes</h4>
                        </Link>
                        <div className="showcase-rating">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                        <div className="price-box">
                          <del>$5.00</del>
                          <p className="price">$4.00</p>
                        </div>
                      </div>
                    </div>
                    <div className="showcase">
                      <Link className="showcase-img-box">
                        <img
                          src="/templates/products/2.jpg"
                          alt="men's hoodies t-shirt"
                          className="showcase-img"
                          width={75}
                          height={75}
                        />
                      </Link>
                      <div className="showcase-content">
                        <Link>
                          <h4 className="showcase-title">men's hoodies t-shirt</h4>
                        </Link>
                        <div className="showcase-rating">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStarHalf />
                        </div>
                        <div className="price-box">
                          <del>$17.00</del>
                          <p className="price">$7.00</p>
                        </div>
                      </div>
                    </div>
                    <div className="showcase">
                      <Link className="showcase-img-box">
                        <img
                          src="/templates/products/3.jpg"
                          alt="girls t-shirt"
                          className="showcase-img"
                          width={75}
                          height={75}
                        />
                      </Link>
                      <div className="showcase-content">
                        <Link>
                          <h4 className="showcase-title">girls t-shirt</h4>
                        </Link>
                        <div className="showcase-rating">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStarHalf />
                        </div>
                        <div className="price-box">
                          <del>$5.00</del>
                          <p className="price">$3.00</p>
                        </div>
                      </div>
                    </div>
                    <div className="showcase">
                      <Link className="showcase-img-box">
                        <img
                          src="/templates/products/4.jpg"
                          alt="woolen hat for men"
                          className="showcase-img"
                          width={75}
                          height={75}
                        />
                      </Link>
                      <div className="showcase-content">
                        <Link>
                          <h4 className="showcase-title">woolen hat for men</h4>
                        </Link>
                        <div className="showcase-rating">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                        <div className="price-box">
                          <del>$15.00</del>
                          <p className="price">$12.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
              <div className="product-container">
                <div className="container">
                  <div className="product-box">
                    {/*AFFICHAGE DE PRODUITS MINIMALISTE PAR CATEGORIE*/}
                    <div className="product-minimal">
                      <div className="product-showcase">
                        <div>
                          <div className="product-showcase">
                            <h2 className="title">{subCategory}</h2>
                            <div className=" has-scrollbar">
                              <div className="showcase-container flex flex-wrap">
                                {paginatedArticles.map(article => (
                                  <div className='m-2 w-1/2 md:w-full showcase-item-container' key={article.id}>
                                    <CardMinimalist article={article} />
                                  </div>
                                ))}
                              </div>
                            </div>

                            {/* Pagination */}
                            <Pagination
                              count={Math.ceil(filteredArticles.length / articlesPerPage)}
                              page={currentPage}
                              onChange={handlePageChange}
                              className="flex justify-center mt-4"
                            />
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </header>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        className='bg-teal-300'
        sx={{
          backgroundColor: 'rgba(0, 128, 128, 0.1)', // Appliquer une couleur de fond aux cellules du corps
        }}
      >
        <DialogTitle >Identification</DialogTitle>
        <DialogContent>
          <section className="  flex items-center justify-center ">
            {/* login container */}
            <div className=" rounded-2xl p-2 items-center md:w-[60%] w-[100%]">

              {/* Tabs */}
              <div className="w-full">
                <Tabs
                  onChange={handleMethodChange}
                  sx={{ mb: 3 }}
                  value={method}
                  variant="fullWidth"
                  className="w-full"
                >
                  <Tab
                    label="Connexion"
                    value="login"
                    className="w-1/2"
                  />
                  <Tab
                    label="Inscription"
                    value="register"
                    className="w-1/2"
                  />
                </Tabs>
              </div>
              <div className='flex'>
                <div className=" w-full px-8 md:px-16">


                  {method === 'login' &&
                    (
                      <>
                        <h2 className="font-bold text-2xl text-[#002D74] text-center">CONNEXION</h2>
                        <p className="text-xs mt-4 text-[#002D74] text-center">
                          Si vous n'avez pas de compte, veuillez vous inscrire
                        </p>
                        <form action="" className="flex flex-col gap-4" onSubmit={(e) => { e.preventDefault(); loginUser(email, password); }}>
                          {/* {error !== '' &&
                            (
                              <div className="mt-3 text-xs flex justify-between items-center text-red-700">
                                <p>{error}</p>
                              </div>
                            )
                          } */}
                          <TextField
                            className="p-2 mt-8 "
                            type="email"
                            name="email"
                            placeholder="E-mail"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <div className="">
                            <TextField
                              className="p-2 w-full"
                              type={show ? "text" : "password"}
                              name="password"
                              placeholder="Mot de passe"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="gray"
                              className={show ? "bi bi-eye-slash absolute top-1/2 right-3 -translate-y-1/2" : "bi bi-eye absolute top-1/2 right-3 -translate-y-1/2"}
                              viewBox="0 0 16 16"
                              onClick={(e) => setShow(!show)}
                            >
                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg> */}
                          </div>
                          <button type='submit' className="bg-orange-400 rounded-xl text-white py-2 hover:scale-105 duration-300">
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Connexion'}
                          </button>
                        </form>
                      </>

                    )
                  }

                  {method === 'register' &&
                    (
                      <>
                        <h2 className="font-bold text-2xl text-[hsl(27, 100%, 57%)] text-center">Inscription</h2>
                        <p className="text-xs mt-4 text-[hsl(27, 100%, 57%)] text-center">
                          Si vous avez déjà un compte, veuillez vous connecter
                        </p>
                        <form action="" className="flex flex-col gap-4" onSubmit={(e) => { e.preventDefault(); registerUser(name, phoneNumber, email, password); }}>
                          {/* {error !== '' &&
                            (
                              <div className="mt-3 text-xs flex justify-between items-center text-red-700">
                                <p>{error}</p>
                              </div>
                            )
                          } */}
                          <TextField
                            className="p-2 mt-8 "
                            type="text"
                            name="name"
                            placeholder="Nom et prénoms"
                            onChange={(e) => setName(e.target.value)}
                          />
                          <TextField
                            className="p-2 mt-8 "
                            type="text"
                            name="email"
                            placeholder="E-mail"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <PhoneInput
                            style={{ backgroundColor: 'white' }}
                            defaultCountry='CI'
                            type='tel'
                            className='transition p-3 bg-transparent border rounded-sm w-[95%]'
                            id="phone"
                            name="phone"
                            placeholder="Entrez le numero de téléphone"
                            value={phoneNumber}
                            onChange={(value) => setPhoneNumber(value)}
                            required
                          />
                          <div className="">
                            <TextField
                              className="p-2  w-full"
                              type={show ? "text" : "password"}
                              name="password"
                              placeholder="Mot de passe"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="gray"
                              className={show ? "bi bi-eye-slash absolute top-1/2 right-3 -translate-y-1/2" : "bi bi-eye absolute top-1/2 right-3 -translate-y-1/2"}
                              viewBox="0 0 16 16"
                              onClick={(e) => setShow(!show)}
                            >
                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg> */}
                          </div>
                          <button type='submit' className="bg-orange-400 rounded-xl text-white py-2 hover:scale-105 duration-300">
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Inscription'}
                          </button>
                        </form>
                      </>

                    )
                  }

                </div>

                {/* image */}
              </div>
              {/* form */}


              {/* Copyright */}
              {/* <div className="mt-auto text-center text-gray-500 w-full">
                © 2024 Fintech Aigle™. Tout droit réservé.
              </div> */}
            </div>
          </section>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ ...alert, open: false })}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>

    </>
  );
};

export default Navigation;