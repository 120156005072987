import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ArticleContext } from '../../contexts/ArticleContext';
import { CartContext } from '../../contexts/CartContext'; // Import du contexte du panier
import { Link, useParams } from 'react-router-dom';

const Products = () => {
  const { articles, selectArticle, selectedArticle } = useContext(ArticleContext);
  const { cart, addToCart, updateCartItemQuantity, } = useContext(CartContext); // Récupération de la fonction d'ajout au panier
  const [quantity, setQuantity] = useState(1); // Gestion de la quantité

  const id = useParams();

  useEffect(() => {
    if (id) {
      selectArticle(id); // Charger les informations du shop à partir du contexte en fonction de l'ID
    }
  }, [id, selectArticle]);

  // Vérifier si l'article est déjà dans le panier
  const findCartItem = () => {
    return cart.find(item => item?._id === selectedArticle?._id);
  };

  // Fonction pour gérer l'ajout au panier avec la quantité
  const handleAddToCart = () => {
    const item = {
      ...selectedArticle,
      quantity: quantity,
      pricePerKilo: selectedArticle?.discount?.applyDiscount
        ? selectedArticle?.discount?.newPrice
        : selectedArticle?.price,
    };
    addToCart(item);
  };
  const currentItem = cart.find(item => item?._id === selectedArticle?._id);
  console.log(currentItem);

  // Gestion de l'augmentation de la quantité
  const handleIncrease = () => {
    setQuantity(quantity + 1);
    updateCartItemQuantity(id, currentItem?.quantity + 1);
  };

  // Gestion de la diminution de la quantité
  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      updateCartItemQuantity(id, currentItem?.quantity - 1);
    }
  };

  // Gestion du changement manuel de la quantité
  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 1) {
      setQuantity(newQuantity);
    }
  };

  return (
    <>
      <section className="py-8 bg-gray-50">
        <div className="container mx-auto px-4">
          {/* Product Information */}
          <div className="flex flex-wrap">
            <div className="w-full lg:w-5/12 mb-8 lg:mb-0">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={30}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                className="rounded-lg overflow-hidden shadow-lg"
              >
                {selectedArticle?.imageUrls?.length > 1 ? (
                  selectedArticle.imageUrls.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        alt="Product"
                        className="w-full h-full object-cover"
                      />
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide>
                    <img
                      src={selectedArticle?.imageUrls}
                      alt="Product"
                      className="w-full h-full object-cover"
                    />
                  </SwiperSlide>
                )}
              </Swiper>
            </div>

            <div className="w-full lg:w-7/12 lg:pl-10">
              <div className="flex items-center mb-8 p-4 bg-white rounded-lg shadow-md">
                {selectedArticle?.shop?.profilePic && (
                  <img
                    src={selectedArticle?.shop?.profilePic}
                    alt={selectedArticle?.shop?.name}
                    className="w-16 h-16 rounded-full object-cover mr-4"
                  />
                )}

                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800">{selectedArticle?.shop?.name}</h3>
                  <Link
                    to={`/shop/${selectedArticle?.shop?._id}`}
                    className="text-blue-500 hover:underline mt-1 inline-block"
                  >
                    Visiter la boutique
                  </Link>
                </div>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h4 className="text-2xl font-bold text-gray-800 mb-4">
                  {selectedArticle?.name}
                </h4>
                <p className="text-gray-600 mb-6">
                  {selectedArticle?.description}
                </p>
                <div className="mb-4">
                  {selectedArticle?.discount?.applyDiscount ? (
                    <>
                      <div className="text-xl text-red-500 font-semibold">
                        {selectedArticle?.discount?.percentDiscount}% de réduction - {selectedArticle?.discount?.newPrice} FCFA
                      </div>
                      <div className="text-lg text-gray-500 line-through">
                        {selectedArticle?.price} FCFA
                      </div>
                    </>
                  ) : (
                    <div className="text-xl font-bold text-gray-800">
                      {selectedArticle?.price} FCFA
                    </div>
                  )}
                </div>

                {/* Sélection de la quantité */}
                {findCartItem() ? (
                  <>
                   {/* <div className="mb-4">
                    <label htmlFor="quantity" className="block text-gray-700 font-semibold mb-2">
                      Quantité
                    </label>
                    <div className='flex'>
                      <button
                        type="button"
                        onClick={() => handleDecrease()}
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                      >
                        -
                      </button>
                      <input
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={quantity}
                        onChange={(e) => handleQuantityChange(e.target.value)}
                        className="py-2 pl-3 text-center w-16 text-base focus:border-neutral-500 focus:outline-none focus:ring-neutral-500"
                        min="1"
                      />
                      <button
                        type="button"
                        onClick={() => handleIncrease()}
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                      >
                        +
                      </button>
                    </div>

                  </div> */}
                  <button
                  className="text-white bg-gray-500 border-0 py-2 px-6 focus:outline-none rounded cursor-default"
                >
                  Article déjà ajouter au panier
                </button>
                  </>
                 
                ) : (// Si l'article n'est pas dans le panier, afficher le bouton "Ajouter au panier"
                  <button
                    onClick={handleAddToCart}
                    className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
                  >
                    Ajouter au panier
                  </button>
                )}

                {/* Bouton ajouter au panier */}
                {/* <button
                  onClick={handleAddToCart}
                  className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
                >
                  Ajouter au panier
                </button> */}

                <div className="mt-4">
                  <span className="text-green-600 font-semibold">
                    En Stock : {selectedArticle?.stock}
                  </span>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
