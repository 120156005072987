import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ShopContext } from '../../contexts/ShopContext';
import { UserContext } from '../../contexts/UserContext';
import { CircularProgress } from '@mui/material';


const User = () => {

  const { user, updateUser, logoutUser } = useContext(UserContext);  // UserContext pour gérer les données utilisateur
  const { shops } = useContext(ShopContext);
  const navigate = useNavigate();
  const { createShop, loading } = useContext(ShopContext);  // ShopContext pour gérer la création de la boutique
  const [editMode, setEditMode] = useState(false);  // État pour basculer entre la vue et l'édition
  const [addShop, setAddshop] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: '',
    phone: '',
    email: '',
    role: '',
  });
  const [shopInfo, setShopInfo] = useState({
    manager: user?.id,
    name: '',
    description: '',
    phoneNumber: '',
    email: '',
    country: '',
    city: '',
    street: '',
    openingHours: '',
    closingHours: '',
  });
  console.log("shops", shops);
  const shopsManaged = shops.filter(shop => shop?.manager?._id === user?.id);
  console.log("manager's shop", shopsManaged);

  useEffect(() => {
    if (user) {
      setShopInfo({ ...shopInfo, manager: user?.id })
    }
  }, []);
  console.log(shopInfo)
  console.log(user)
  // Charger les informations de l'utilisateur dans le formulaire
  useEffect(() => {
    if (user) {
      setUserInfo({
        name: user?.user?.name,
        phone: user?.user?.phone,
        email: user?.user?.email,
        role: user?.user?.role,
      });
    }
  }, [user]);

  // Gestionnaire de mise à jour des informations utilisateur
  const handleUserChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  // Gestionnaire de soumission pour la mise à jour de l'utilisateur
  const handleUserSubmit = (e) => {
    e.preventDefault();
    updateUser(userInfo);  // Mettre à jour les informations utilisateur via le contexte
    setEditMode(false);
  };


  // Gestionnaire de soumission pour la création de la boutique
  const handleShopSubmit = (e) => {
    e.preventDefault();
    createShop(shopInfo);  // Créer une boutique via le contexte
    // Ajouter des actions supplémentaires ici, comme rediriger vers la page de la boutique
  };

  // if (loading) {
  //   return <p>Chargement en cours...</p>;
  // }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://demos.creative-tim.com/notus-js/assets/styles/tailwind.css"
      />
      <link
        rel="stylesheet"
        href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"
      />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                'url("/images/profil-banner.jpg")'
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            />
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0px)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x={0}
              y={0}
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src="/images/user-account.png"
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0 flex">
                      <button
                        className="bg-orange-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          setEditMode(true);
                          setAddshop(false);
                        }}
                      >
                        Modifier les informations
                      </button>
                      <button
                        className="bg-orange-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          setAddshop(true);
                          setEditMode(false);
                          setUserInfo({ ...userInfo, role: 'manager' })
                        }}
                      >
                        {userInfo?.role === 'manager' ? ("Crée une boutique") : ("Devenir manager")}
                      </button>
                      <button
                        className="bg-orange-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          logoutUser();
                          navigate("/");
                        }}
                      >
                        Deconnexion
                      </button>
                    </div>

                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          0
                        </span>
                        <span className="text-sm text-blueGray-400">Commandes</span>
                      </div>
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          0
                        </span>
                        <span className="text-sm text-blueGray-400">Favoris</span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          0
                        </span>
                        <span className="text-sm text-blueGray-400">Commentaires</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    {userInfo?.name}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i className="fas fa-phone mr-2 text-lg text-blueGray-400" />
                    {userInfo?.phone}
                  </div>
                  <div className="mb-2 text-blueGray-600 mt-10">
                    <i className="fas fa-paper-plane mr-2 text-lg text-blueGray-400" />
                    {userInfo?.email}
                  </div>
                  {/* <div className="mb-2 text-blueGray-600">
                    <i className="fas fa-university mr-2 text-lg text-blueGray-400" />
                    University of Computer Science
                  </div> */}
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      {editMode ? (
                        <form onSubmit={handleUserSubmit}>
                          <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="name">Nom :</label>
                            <input
                              type="text"
                              name="name"
                              value={userInfo?.name}
                              onChange={handleUserChange}
                              className="w-full px-3 py-2 border rounded"
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="phone">Téléphone :</label>
                            <input
                              type="tel"
                              name="phone"
                              value={userInfo?.phone}
                              onChange={handleUserChange}
                              className="w-full px-3 py-2 border rounded"
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="email">Email :</label>
                            <input
                              type="email"
                              name="email"
                              value={userInfo?.email}
                              onChange={handleUserChange}
                              className="w-full px-3 py-2 border rounded"
                            />
                          </div>
                          <div className='flex justify-between'>
                            <button type="submit" className="bg-blue-500  text-white px-4 py-2 rounded">
                              Sauvegarder
                            </button>
                            <button onClick={() => setEditMode(false)} className="bg-red-500  text-white px-4 py-2 rounded">
                              Annuler
                            </button>
                          </div>

                        </form>
                      ) : addShop ? (
                        <div className="mt-8">
                          <h2 className="text-xl font-bold mb-4">Créer une boutique</h2>
                          <form onSubmit={handleShopSubmit}>
                            <div className="mb-4">
                              <label className="block text-sm font-bold mb-2" htmlFor="shopName">Nom de la boutique :</label>
                              <input
                                type="text"
                                name="shopName"
                                value={shopInfo.name}
                                onChange={(e) => setShopInfo({ ...shopInfo, name: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block text-sm font-bold mb-2" htmlFor="shopName">Description de la boutique :</label>
                              <input
                                type="text"
                                name="shopDescription"
                                value={shopInfo.description}
                                onChange={(e) => setShopInfo({ ...shopInfo, description: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block text-sm font-bold mb-2" htmlFor="shopName">Adresse de la boutique :</label>
                              <input
                                type="text"
                                name="shopCountry"
                                placeholder='Pays'
                                value={shopInfo.country}
                                onChange={(e) => setShopInfo({ ...shopInfo, country: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                                required
                              />
                              <input
                                type="text"
                                name="shopCity"
                                placeholder='Ville'
                                value={shopInfo.city}
                                onChange={(e) => setShopInfo({ ...shopInfo, city: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                                required
                              />
                              <input
                                type="text"
                                name="shopStreet"
                                placeholder='Rue'
                                value={shopInfo.street}
                                onChange={(e) => setShopInfo({ ...shopInfo, street: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block text-sm font-bold mb-2" htmlFor="openingHours">Numéro de téléphone de la boutique :</label>
                              <input
                                type="number"
                                name="shopPhone"
                                value={shopInfo.phoneNumber}
                                onChange={(e) => setShopInfo({ ...shopInfo, phoneNumber: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block text-sm font-bold mb-2" htmlFor="openingHours">email de la boutique (optionnel):</label>
                              <input
                                type="email"
                                name="email"
                                value={shopInfo.email}
                                onChange={(e) => setShopInfo({ ...shopInfo, email: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block text-sm font-bold mb-2" htmlFor="openingHours">Heures d'ouverture :</label>
                              <input
                                type="time"
                                name="openingHours"
                                value={shopInfo.openingHours}
                                onChange={(e) => setShopInfo({ ...shopInfo, openingHours: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block text-sm font-bold mb-2" htmlFor="closingHours">Heures de fermeture :</label>
                              <input
                                type="time"
                                name="closingHours"
                                value={shopInfo.closingHours}
                                onChange={(e) => setShopInfo({ ...shopInfo, closingHours: e.target.value })}
                                className="w-full px-3 py-2 border rounded"
                                required
                              />
                            </div>
                            <div className='flex justify-between'>
                              <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Crée la boutique'}
                              </button>
                              <button onClick={() => setAddshop(false)} className="bg-red-500 text-white px-4 py-2 rounded">
                                Annuler
                              </button>
                            </div>

                          </form>
                        </div>
                      ) : ("")
                      }
                      {/* <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                        An artist of considerable range, Jenna the name taken by
                        Melbourne-raised, Brooklyn-based Nick Murphy writes,
                        performs and records all of his own music, giving it a warm,
                        intimate feel with a solid groove structure. An artist of
                        considerable range.
                      </p>
                      <a href="#pablo" className="font-normal text-pink-500">
                        Show more
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {userInfo?.role === 'manager' && (
            <div className='container mx-auto px-4'>
              <div className='mt-8 bg-white p-6 relative flex flex-col min-w-0 break-words mb-6 shadow-xl rounded-lg'>
                <div className="w-full lg:w-4/12 px-4 lg:order-1">
                  <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    <div className="mr-4 p-3 text-center">
                      <h3 className=" text-blueGray-400">Liste des boutiques gérées</h3>
                    </div>
                  </div>
                  {shopsManaged.map((shop) => (
                      <div className="flex items-center mb-8 p-4 bg-white rounded-lg shadow-md">
                        {shop?.profilePic && (
                          <img
                            src={shop?.profilePic}
                            alt={shop?.name}
                            className="w-16 h-16 rounded-full object-cover mr-4"
                          />
                        )}

                        <div className="flex-1">
                          <h3 className="text-xl font-semibold text-gray-800">{shop?.name}</h3>
                          <Link
                            to={`/shop/${shop?._id}`}
                            className="text-blue-500 hover:underline mt-1 inline-block"
                          >
                            Visiter la boutique
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )
          }


        </section>

      </main>


      {/* <div className="container mx-auto p-6">
        <h1 className="text-2xl font-bold mb-4">Mon Profil</h1>
        <button
          onClick={() => navigate("/")}
          className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
        >
          Retour sur la page d'accueil
        </button>
        <button
          onClick={() => {
            logoutUser();
            navigate("/");
          }}
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
        >
          Deconnexion
        </button>
        {!editMode ? (
          <div>
            <p><strong>Nom : </strong>{userInfo?.name}</p>
            <p><strong>Téléphone : </strong>{userInfo?.phone}</p>
            <p><strong>Email : </strong>{userInfo?.email}</p>
            <p><strong>Statut : </strong>{userInfo?.role}</p>

            <button onClick={() => setEditMode(true)} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
              Modifier mes informations
            </button>

            {userInfo?.role === 'user' && (
              <button
                onClick={() => setUserInfo({ ...userInfo, role: 'manager' })}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
              >
                Devenir Manager
              </button>
            )}
          </div>
        ) : (
          <form onSubmit={handleUserSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="name">Nom :</label>
              <input
                type="text"
                name="name"
                value={userInfo?.name}
                onChange={handleUserChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="phone">Téléphone :</label>
              <input
                type="text"
                name="phone"
                value={userInfo?.phone}
                onChange={handleUserChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="email">Email :</label>
              <input
                type="email"
                name="email"
                value={userInfo?.email}
                onChange={handleUserChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>

            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
              Sauvegarder
            </button>
          </form>
        )}

        {userInfo.role === 'manager' && (
          <div className="mt-8">
            <h2 className="text-xl font-bold mb-4">Créer une boutique</h2>
            <form onSubmit={handleShopSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="shopName">Nom de la boutique :</label>
                <input
                  type="text"
                  name="shopName"
                  value={shopInfo.name}
                  onChange={(e) => setShopInfo({ ...shopInfo, name: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="shopName">Description de la boutique :</label>
                <input
                  type="text"
                  name="shopDescription"
                  value={shopInfo.description}
                  onChange={(e) => setShopInfo({ ...shopInfo, description: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="shopName">Adresse de la boutique :</label>
                <input
                  type="text"
                  name="shopCountry"
                  placeholder='Pays'
                  value={shopInfo.country}
                  onChange={(e) => setShopInfo({ ...shopInfo, country: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                <input
                  type="text"
                  name="shopCity"
                  placeholder='Ville'
                  value={shopInfo.city}
                  onChange={(e) => setShopInfo({ ...shopInfo, city: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                <input
                  type="text"
                  name="shopStreet"
                  placeholder='Rue'
                  value={shopInfo.street}
                  onChange={(e) => setShopInfo({ ...shopInfo, street: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="openingHours">Numéro de téléphone de la boutique :</label>
                <input
                  type="text"
                  name="shopPhone"
                  value={shopInfo.phoneNumber}
                  onChange={(e) => setShopInfo({ ...shopInfo, phoneNumber: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="openingHours">email de la boutique (optionnel):</label>
                <input
                  type="email"
                  name="email"
                  value={shopInfo.email}
                  onChange={(e) => setShopInfo({ ...shopInfo, phoneNumber: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="openingHours">Heures d'ouverture :</label>
                <input
                  type="time"
                  name="openingHours"
                  value={shopInfo.openingHours}
                  onChange={(e) => setShopInfo({ ...shopInfo, openingHours: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="closingHours">Heures de fermeture :</label>
                <input
                  type="time"
                  name="closingHours"
                  value={shopInfo.closingHours}
                  onChange={(e) => setShopInfo({ ...shopInfo, closingHours: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                Créer la boutique
              </button>
            </form>
          </div>
        )}
      </div> */}
    </>

  );
};

export default User;