import React from 'react';
import Main from '../components/home/Main';
import Footer from '../components/home/Footer';
import Navigation from '../components/home/Navigation';

const HomePage = () => {
  return (
    <>
      <Navigation />
      <Main />
      <Footer />
    </>
  );
};

export default HomePage;