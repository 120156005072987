import React from 'react';
import Navigation from '../components/home/Navigation';
import Footer from '../components/home/Footer';
import Products from '../components/home/Products';

const DetailsProducts = () => {
  return (
    <>
      <Navigation />
      <Products />
      <Footer />
    </>
  );
};

export default DetailsProducts;